import { observer } from 'mobx-react';
import { AccountStoreContext } from '../store/AccountStore';
import React, { useContext, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, collection, query, where, getDocs, updateDoc } from "firebase/firestore";

const ButtonsMenu = observer(({getData, generateImagesFromDescriptions}) => {
    const accountStore = useContext(AccountStoreContext);
    const [running, setRunning] = useState(false);
    const [isSaved, setIsSaved] = useState(true);

    const handleSave = async () => {
        if (!accountStore || !accountStore.accountName) {
            console.error("accountStore or accountStore.accountName is undefined");
            return;
        }
    
        setIsSaved(false);
    
        const auth = getAuth();
        const user = auth.currentUser;
        const db = getFirestore();
    
        if (user) {
            const accountQuery = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
            const querySnapshot = await getDocs(accountQuery);
    
            if (!querySnapshot.empty) {
                const docData = querySnapshot.docs[0];
                const accountDoc = doc(db, "accounts", docData.id);
        
                try {
                    await updateDoc(accountDoc, {
                        keyword1: accountStore.keyword1,
                        keyword2: accountStore.keyword2,
                        keyword3: accountStore.keyword3,
                        daily: accountStore.daily,
                        startDate: accountStore.startDate,
                        startMinute: accountStore.startMinute,
                        startHour: accountStore.startHour,
                        backlink: accountStore.backlink,
                        hashtags: accountStore.hashtags,
                        tone: accountStore.tone,
                        wordCount: accountStore.wordCount,
                    });
                } catch (error) {
                    console.error("Error updating document:", error);
                }
            } else {
                console.error("No document found with accountName:", accountStore.accountName);
            }
        }
    
        setIsSaved(true);
      };

    const handlePreview = async () => {
        if (!accountStore) {
            console.error("accountStore is undefined");
            return;
        }

        if (running === false) {
            setRunning(true);

            try {
            accountStore.setGeneratingContent(true);
            accountStore.setHamburgerOpen(false);
            const result = await getData();
            let cards = [];
            console.log("Result", result);
            for (let i = 0; i < result.choices.length; i++) {
                const message = JSON.parse(result.choices[i].message.content);
                console.log("Message", message);
                for (let j = 0; j < message.textResponse.length; j++) {
                    const textResponse = message.textResponse[j];
                    const obj = {
                        text: textResponse.text,
                        title: textResponse.title,
                        hashtags: accountStore.signatures
                        ? [...accountStore.signatures, ...textResponse.hashtags]
                        : textResponse.hashtags,
                        wordCount: textResponse.wordCount,
                        imageDescription: message.imageResponse[j].image,
                    };
                    cards.push(obj);
                }
            }

            try {
                const images = await generateImagesFromDescriptions([
                cards[0].imageDescription,
                cards[1].imageDescription,
                cards[2].imageDescription,
                ]);

                images.forEach((image, index) => {
                cards[index].image = image;
                });

                console.log("cards", cards);

                accountStore.setCards([]);
                accountStore.setCards(cards);
                accountStore.setInitialImage(cards[0].image);
                accountStore.setInitialText(cards[0].text);
            } catch (err) {
                console.error("Error generating images:", err);
            } finally {
                accountStore.setGeneratingContent(false);
                setRunning(false);
            }
            } catch (error) {
                console.error("Error fetching data:", error);
                accountStore.setGeneratingContent(false);
                setRunning(false);
            }
        } else {
            setRunning(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: "center", marginTop: 16, marginBottom: 16 }}>
            <div style={{ height: 30, width: 70, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, borderRadius: 10, marginLeft: 10, marginTop: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontSize: 16 }}
            className="button"
            onClick={handleSave}>
                {accountStore.selectedAccount !== "" && isSaved === false ? 'Saving...' : 'Save'}
            </div>
            <div style={{ height: 30, width: 70, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, borderRadius: 10, marginLeft: 10, marginTop: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular' }}
            className="button"
            onClick={handlePreview}>
                {running === true ? 'Pause' : 'Preview'}
            </div>
        </div>
    );
})
export default ButtonsMenu;