import { observer } from 'mobx-react';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { AccountStoreContext } from '../store/AccountStore';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';


const Header = observer(() => {
  const user = useContext(AuthContext);
  const accountStore = useContext(AccountStoreContext);
  const [profileImage, setProfileImage] = useState("");
  const photoURL = user ? user.photoURL : null;

  useEffect(() => {
    setProfileImage(photoURL);
  }, [photoURL, user]);

  const handleHamburgerClick = () => { // Add this function to handle hamburger menu click
    accountStore.setHamburgerOpen(!accountStore.isHamburgerOpen);
  };

  return (
    <div style={{ background: 'white', height: 70, border: '0px solid black', justifyContent: 'space-between', margin: 'auto', alignItems: 'center', paddingLeft: 15, paddingRight: 15, position: 'relative', width: '100%', display: 'flex' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <img src="./hamburger-menu.png" alt="" onClick={handleHamburgerClick} style={{ cursor: 'pointer', width: '7%', height: '7%' }} />
        <img src="./ding-data.svg" alt="" />
        <div style={{ height: 30, width: 30, borderRadius: '50%', overflow: 'hidden' }}>
          <img src={profileImage} alt="" style={{ height: '100%', width: '100%' }} />
        </div>
        {/* If Hamburger menu is open render HamburgerMenu component */}
        {accountStore.hamburgerOpen && <HamburgerMenu closeMenu={handleHamburgerClick} />}
        {/* Rest of your code */}
      </div>
    </div>
  );
})
export default Header;