import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';

const Payment = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  let payment = accountStore.payment;
  const [postalValue, setPostalValue] = useState(payment.postal);
  const [cardNumberValue, setCardNumberValue] = useState(payment.cardNumber);
  const [cvvValue, setCVVValue] = useState(payment.cvv);
  const [expiringValue, setExpiringValue] = useState(payment.expiring);
  
  const handlePostal = (event) => {
    const value = event.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setPostalValue(value);
      payment.postal = value;
      accountStore.setPayment(payment);
    }
  };
  const handleCVV = (event) => {
    const value = event.target.value;
    if (/^\d{0,3}$/.test(value)) {
      setCVVValue(value);
      payment.cvv = value;
      accountStore.setPayment(payment);
    }
  };
  const handleCardNumber = (event) => {
    const value = event.target.value;
    if (/^\d{0,16}$/.test(value)) {
      setCardNumberValue(value);
      payment.cardNumber = value;
      accountStore.setPayment(payment);
    }
  };
  const handleExpiring = (event) => {
    let value = event.target.value;

    // Validation based on input length
    if (value.length === 1) {
      // First character should be 0 or 1
      if (!/[01]/.test(value)) {
        return;
      }
    } else if (value.length === 2) {
      // Second character depends on the first character
      if (expiringValue[0] === '0' && !/[1-9]/.test(value[1])) {
        return;
      } else if (expiringValue[0] === '1' && !/[0-2]/.test(value[1])) {
        return;
      }
    } else if (value.length === 3) {
      // Third character should be a "/"
      if (value[2] !== '/') {
        return;
      }
    } else if (value.length > 3 && value.length <= 7) {
      // Characters 4-7 should be digits
      if (!/^\d{0,4}$/.test(value.slice(3))) {
        return;
      }
    }
    setExpiringValue(value);

    // If input is fully valid as MM/YYYY, update expiringValue
    if (value.length === 7 && /^(0[1-9]|1[0-2])\/\d{4}$/.test(value)) {
      setExpiringValue(value);
      payment.expiring = value;
      accountStore.setPayment(payment);
    }// Update expiringValue if valid
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '75%', background: 'white', position: 'relative', marginTop: 20, borderRadius: 15, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 32px', marginBottom: 20, marginLeft: 'auto', marginRight: 'auto', padding: 16, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
        <label style={{ paddingTop: 5, paddingBottom: 5, fontSize: 11, color: 'gray' }}>
          Credit Card Number
        </label>
        <input placeholder='1234 5678 9012 3456' type="text" value={cardNumberValue} onChange={handleCardNumber} style={{ outline: 0, borderWidth: '0 0 2px', fontSize: 16, color: 'black', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }}></input>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }} >
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 10 }}>
          <label style={{ paddingTop: 5, paddingBottom: 5, fontSize: 11, color: 'gray' }}>
            Expiring
          </label>
          <input placeholder='MM/YYYY' type="text" maxLength={7} value={expiringValue} onChange={handleExpiring} style={{ outline: 0, borderWidth: '0 0 2px', fontSize: 16, color: 'black', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 10 }}>
          <label style={{ paddingTop: 5, paddingBottom: 5, fontSize: 11, color: 'gray' }}>
            CVV
          </label>
          <input placeholder='CVV Code' type="text" value={cvvValue} onChange={handleCVV} style={{ outline: 0, borderWidth: '0 0 2px', fontSize: 16, color: 'black', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }}></input>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 10, width: '46%' }}>
        <label style={{ paddingTop: 5, paddingBottom: 5, fontSize: 11, color: 'gray' }}>
          Postal Number
        </label>
        <input placeholder='123456' type="text" value={postalValue} onChange={handlePostal} style={{ outline: 0, borderWidth: '0 0 2px', fontSize: 16, color: 'black', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }}></input>
      </div>
    </div>
  );
})
export default Payment;