import { observer } from 'mobx-react-lite';
import { AccountStoreContext } from '../store/AccountStore';
import React, { useContext } from 'react';
import FormMenu from '../FormMenu/FormMenu.js';
import { keywordTraining } from '../Training.js';
import ButtonsMenu from '../ButtonsMenu/ButtonsMenu.js';
import ErrorsComponent from '../ErrorsComponent/ErrorsComponent';

const KeywordPosting = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    async function getData() {
        const training = keywordTraining(accountStore);
        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accountStore.openAIKey}`
                },
                body: JSON.stringify({
                model: "gpt-4",
                messages: training
                }),
            });
        
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        
            const chatText = await response.text();
            const chatObject = JSON.parse(chatText);
        
            training.push({
                role: "assistant",
                content: JSON.stringify(chatText),
            });
        
            return chatObject;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    
    const generateImage = async (description) => {
        const response = await fetch("https://api.openai.com/v1/images/generations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accountStore.openAIKey}`
            },
            body: JSON.stringify({
                prompt: description,
                size: "1024x1024"
            }),
        });
    
        const data = await response.json();
        return data;
    };
    
    async function generateImagesFromDescriptions(descriptions) {
        let imageArr = [];
    
        for (let i = 0; i < descriptions.length; i++) {
            try {
                let data = await generateImage(descriptions[i]);
                console.log(data);
                imageArr.push(data.data[0].url);
            } catch (err) {
                console.error(err);
            }
        }
    
        return imageArr;
    }
    
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: 'auto', background: 'white', alignItems: 'center', padding: 8 }}>
                <input id="keyword1" style={{ background: '#F5F5F5', borderRadius: 8, border: '0px solid', height: '30px', margin: '4px 0px', width: '100%', paddingLeft: 8, boxSizing:'border-box' }} 
                type="text"
                placeholder="Keyword 1"
                onChange={(e) => {
                    accountStore.setKeyword1(e.target.value);
                }} 
                value={accountStore.keyword1} />
                <input id="keyword2" style={{ background: '#F5F5F5', borderRadius: 8, border: '0px solid', height: '30px', margin: '4px 0px', width: '100%', paddingLeft: 8, boxSizing:'border-box' }}
                type="text" 
                placeholder="Keyword 2"
                onChange={(e) => {
                    accountStore.setKeyword2(e.target.value);
                }} 
                value={accountStore.keyword2} />
                <input id="keyword3" style={{ background: '#F5F5F5', borderRadius: 8, border: '0px solid', height: '30px', margin: '4px 0px', width: '100%', paddingLeft: 8, boxSizing:'border-box' }}
                type="text" 
                placeholder="Keyword 3"
                onChange={(e) => {
                    accountStore.setKeyword3(e.target.value);
                }}
                value={accountStore.keyword3} />
            </div>
            <FormMenu />
            <ButtonsMenu 
                getData={getData}
                generateImagesFromDescriptions={generateImagesFromDescriptions}
            />
            <ErrorsComponent />
        </div>
    );
})
export default KeywordPosting;