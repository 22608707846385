import { observer } from 'mobx-react';
import { useState } from 'react';
import UsersView from '../UsersView/UsersView';
const ManagementView = observer(() => {

  const [activeTab, setActiveTab] = useState('User');
  const tabStyle = {
    cursor: 'pointer',
    marginRight: '10px',
    padding: '5px',
    borderBottom: '2px solid transparent',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white'
  };

  const activeTabStyle = {
    ...tabStyle,
    borderBottom: '2px solid rgba(0, 0, 255)',
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  };

  return (
    <div style={{ width: '100%', marginLeft: 50, marginRight: 50, marginBottom: 25 }}>
      <div style={{ display: 'flex', height: '11%', flexDirection: 'column' }}>
        <p style={{ fontSize: 38, fontWeight: 'bold', fontFamily: 'nexa_boldregular', margin: 8, display: 'flex', marginLeft: 10, marginTop: 10, alignItems: 'center' }}>
          Management
        </p>
        <div style={{ display: 'flex', justifyContent: 'start', paddingLeft: 0, left: 10,  width: '50%' }}>
          <div style={{ height: 30, width: '100%', margin: 'auto', display: 'flex', border: 1 }}>
            <div style={activeTab === 'User' ? activeTabStyle : tabStyle} onClick={() => setActiveTab('User')}>
              User
            </div>
            <div style={activeTab === 'Logs' ? activeTabStyle : tabStyle} onClick={() => setActiveTab('Logs')}>
              Logs
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', height: 'calc(88% - 25px)', backgroundColor: 'white'}}>
        {activeTab === 'User' && <UsersView />}
      </div>
    </div>
  );
})
export default ManagementView;