import React, { useContext, useState, useEffect } from 'react';
import ManagementView from '../ManagementView/ManagementView';
import { AccountStoreContext } from '../store/AccountStore';
import { observer } from 'mobx-react-lite'; // import observer
import HeaderMobile from '../Header/HeaderMobile'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ProfileSection from '../ProfileSection/ProfileSection';
import TabMenuMobile from '../TabMenuMobile/TabMenuMobile';
import { checkAdminAndFetch } from '../Dashboard/DashboardLogic'

const DashboardMobile = observer(() => { // wrap your component with observer

  const [managementSelected, setManagement] = useState(false);
  const accountStore = useContext(AccountStoreContext);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
      const auth = getAuth();
  
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is authenticated, proceed with fetching data
          checkAdminAndFetch(accountStore, setIsAdmin);
        } else {
          console.error("User is not authenticated.");
        }
      });
  
      // Cleanup the listener on unmount
      return () => unsubscribe();
    }, [accountStore, setIsAdmin]);


  const handleButtonClick = () => {
    setManagement(!managementSelected);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: 'white' }}>
      <style>
        {`
          ::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        `}
      </style>
      <div style={{ display: 'flex', width: '100%', height: 70, background: '#FFFFFF', alignItems: 'center' }}>
        <HeaderMobile isAdmin={isAdmin} onButtonClick={handleButtonClick} />
      </div>
      {!accountStore.showProfile ? (
        <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 70px)', width: '100%', overflowX: 'hidden' }}>
          {managementSelected ?
            <ManagementView /> :
            <TabMenuMobile />
          }
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 70px)', overflowX: 'hidden' }}>
          <ProfileSection />
        </div>
      )}
    </div>
  );
})

export default DashboardMobile;