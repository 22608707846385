import React, { useContext, useState } from 'react';
import { AccountStoreContext } from '../store/AccountStore';
import AccountDropdown from '../AccountDropdown/AccountDropdown';


const HamburgerMenu = ({ closeMenu }) => {
  const accountStore = useContext(AccountStoreContext);
  const [activeDropdown, setActiveDropdown] = useState(false);

  const handleClick = (route) => {
    accountStore.setHamburgerOpen(false)
    // navigate to the route
  };
  const toggleDropdown = () => {
    setActiveDropdown(!activeDropdown);
  };

  return (
    <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 100, height: '100vh', overflowY: 'scroll' }}>
      <div style={{ backgroundColor: '#fff', width: 300, height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
        <ul style={{ borderColor: '#e5e7eb', borderWidth: 0, borderStyle: 'solid', boxSizing: 'border-box', listStyle: 'none', padding: 0, margin: 0, backgroundColor: '#FFFFFF' }}>
          <li>
          <button onClick={handleClick} style={{ marginBottom: 10 }}>Close Menu</button>
          </li>
        </ul>
        <div style={{ display: 'flex', width: 'calc(100% - 40px)', position: 'relative', alignItems: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontWeight: 'bold', fontSize: 18 }}
          onClick={toggleDropdown}>
          <img src={accountStore.selectedAccountData && accountStore.selectedAccountData.profileImage ? accountStore.selectedAccountData.profileImage : './user.svg'} alt="" style={{ marginRight: 10, width: 30, height: 30, borderRadius: '50%', backgroundSize: 'contain' }} />
          {accountStore.selectedAccountData && accountStore.selectedAccountData.accountName ? accountStore.selectedAccountData.accountName : "Select Account"}
          <span style={{ position: 'absolute', right: 0, transform: activeDropdown === true ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}>
            <img src="./down-arrow.svg" alt="" />
          </span>
        </div>
        {activeDropdown && (
          <div style={{ width: 300, position: 'absolute', background: '#FFFFFF', boxShadow: 'rgba(0, 0, 0, 0.1) -4px 4px 8px' }}>
            <AccountDropdown />
          </div>
        )}
      </div>
    </div>
  );
};

export default HamburgerMenu;
