import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';
import CardSection from '../CardSection/CardSection.js';
import SocialSection from '../SocialSection/SocialSection.js';
import { IsMobileContext } from '../Tools/IsMobileContext';

const PreviewSection = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const isMobile = useContext(IsMobileContext);

  const itemsSocialSection = [
    {
      title: 'Facebook',
      src: accountStore.initialImage,
      text: accountStore.initialText,
      social: './facebook.svg'
    },
    {
      title: 'Instagram',
      src: accountStore.initialImage,
      text: accountStore.initialText,
      social: './ig.svg'
    },
    {
      title: 'Twitter',
      src: accountStore.initialImage,
      text: accountStore.initialText,
      social: './twitter.svg'
    },
    {
      title: 'LinkedIn',
      src: accountStore.initialImage,
      text: accountStore.initialText,
      social: './linkedin.svg'
    },
    {
      title: 'Blog',
      src: accountStore.initialImage,
      text: accountStore.initialText,
      social: './blog.svg'
    },
  ];

  return (
    <div style={{ width: 'calc(100% - 40px)', height: '100%', marginLeft: 20, marginRight: 20 }}>
      <div id="desktop-content" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        { !isMobile && <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', margin: 8 }}>
          Preview
        </p>}
        {accountStore.cards.map((card, index) => (
          <CardSection
            key={`${card.title}-${index}`}
            index={index}
            title={card.title}
            text={card.text}
            hashtags={card.hashtags}
            wordCount={card.wordCount}
            image={card.image}
            imageDescription={card.imageDescription}
          />
        ))}
      </div>
      <div id="social-desktop-wrap" style={{ width: '100%' }}>
        <SocialSection items={itemsSocialSection} />
      </div>
    </div>
  );
})
export default PreviewSection;