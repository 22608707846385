import { observer } from 'mobx-react';
import React, { useState, useContext } from 'react';
import PostMenu from '../PostMenu/PostMenu.js';
import { AccountStoreContext } from '../store/AccountStore';
import { IsMobileContext } from '../Tools/IsMobileContext';

const items = [
  {
    title: 'Title 1',
    content: 'Content 1',
    image: './calendar.svg'
  },
  {
    title: 'Title 2',
    content: 'Content 2',
    image: './clock.svg'
  },
  {
    title: 'Title 3',
    content: 'Content 3',
    image: './frequency.svg'
  },
];

const formatText = (text) => {
  const regex = /<[^>]*>/g;
  const newText = text.replace(regex, '');
  return newText;
};

const RightSection = observer(() => {
  const isMobile = useContext(IsMobileContext);
  const [reRenderPostMenu, setReRenderPostMenu] = useState(true);
  const accountStore = useContext(AccountStoreContext);
  const [frequency, setFrequency] = useState('daily');

  async function postTextToSocialMedia(postText, mediaUrl) {
    let socialArray = [];
    const formattedText = formatText(postText);

    console.log('formattedText', formattedText);
    console.log('image', mediaUrl);

    if (accountStore.twitter) socialArray.push("twitter");
    if (accountStore.instagram) socialArray.push("instagram");
    if (accountStore.facebook) socialArray.push("facebook");
    if (accountStore.linkedIn) socialArray.push("linkedIn");

    console.log('socialArray', socialArray);

    fetch("https://app.ayrshare.com/api/post", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accountStore.ayrKey}`,
      },
      body: JSON.stringify({
        post: formattedText,
        platforms: socialArray,
        mediaUrls: [mediaUrl],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <div style={{ width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'space-between', display: 'flex' }}>
      {!isMobile && <p style={{ fontSize: 33, fontWeight: 'bold', position: 'relative', fontFamily: 'nexa_boldregular', margin: 8, height: 38 }}>
        Post
      </p>}
      {reRenderPostMenu ? <PostMenu items={items} frequency={frequency} setFrequency={setFrequency} reloadMenu={() => {
        setReRenderPostMenu(false);
        setReRenderPostMenu(true);
      }} /> : <PostMenu items={items} frequency={frequency} setFrequency={setFrequency} reloadMenu={() => {
        setReRenderPostMenu(false);
        setReRenderPostMenu(true);
      }} />
      }
      <div style={{ height: 30, width: 70, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, borderRadius: 10, marginLeft: 10, marginTop: 10, marginBottom: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular' }}
        className="button"
        onClick={async () => {
          try {
            await postTextToSocialMedia(`${accountStore.cards[0].title}\n${accountStore.cards[0].hashtags}\n${accountStore.backlink}`, accountStore.cards[0].image);
          } catch (e) {
            alert('problem with your api key')
          }
        }}>Post
      </div>
      <div style={{ width: '100%', background: 'white', height: 56, display: 'flex', alignItems: 'center', justifyContent: 'end', boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.1)' }}>
        <img src="./ding-data.svg" alt="" style={{ padding: 10 }} />
      </div>
    </div>
  );
})
export default RightSection;