import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { Timestamp } from 'firebase/firestore';

class AccountStore {
  showProfile = false;
  plan = "";
  time = "";
  dates = "";
  backlink = "";
  hashtags = "";
  tone = "";
  accounts = [];
  daily = "";
  keyword1 = "";
  keyword2 = "";
  keyword3 = "";
  accountName = "";
  startDate = Timestamp.fromDate(new Date());
  startHour = "";
  startMinute = "";
  frequency = "";
  signatures = [];
  inventory= [];
  payment = {
    postal: '',
    cardNumber: '',
    cvv: '',
    expiring: ''
  };
  cards = [
    {
      title: 'AI generated title here.',
      text: 'This is placeholder text. Generated AI article previews will be visible here. These article previews will be the first 3 articles sent. Our AI system analyzes various sources and generates summaries for your convenience. You can expect concise, informative previews that highlight the key points of each article. Stay tuned for the latest updates and insights from our AI-powered content generator. We aim to provide you with timely and relevant information, ensuring you stay informed and engaged.',
      hashtags: ['#hashtag', '#hashtag2', '#hashtag3'],
      wordCount: 0,
      image: 'default-photo.svg',
      imageDescription: ''
    },
    {
      title: 'AI generated title here.',
      text: 'This is placeholder text. Generated AI article previews will be visible here. These article previews will be the first 3 articles sent. ',
      hashtags: ['#hashtag', '#hashtag2', '#hashtag3'],
      wordCount: 0,
      image: 'default-photo.svg',
      imageDescription: ''
    },
    {
      title: 'AI generated title here.',
      text: 'This is placeholder text. Generated AI article previews will be visible here. These article previews will be the first 3 articles sent. Our AI system analyzes various sources and generates summaries for your convenience. Expect concise, informative previews that highlight key points of each article. Stay for the latest updates.',
      hashtags: ['#hashtag', '#hashtag2', '#hashtag3'],
      wordCount: 0,
      image: 'default-photo.svg',
      imageDescription: ''
    }
  ];

  openAIKey = "";
  ayrKey = "";
  selectedAccount = "";
  generatingContent = false;
  initialImage = "https://via.placeholder.com/150";
  initialText = "No generated text yet...";
  instagram = false;
  twitter = false;
  facebook = false;
  linkedIn = false;
  blog = false;
  hamburgerOpen = false;
  profileImage = 'default.jpeg';
  selectedAccountData = null;
  email = "";
  phone = "";
  website = "";
  wordCount = "";

  constructor() {
    makeAutoObservable(this);
  }

  setShowProfile(showProfile) {
    this.showProfile = showProfile;
  }

  setPlan(plan) {
    this.plan = plan;
  }

  setTime(time) {
    this.time = time;
  }

  setDates(dates) {
    this.dates = dates;
  }

  setBackLink(backlink) {
    this.backlink = backlink;
  }

  setHashTags(hashtags) {
    this.hashtags = hashtags;
  }

  setTone(tone) {
    this.tone = tone;
  }

  setAccounts(accounts) {
    this.accounts = accounts;
  }

  setDaily(daily) {
    this.daily = daily;
  }

  addAccount(account) {
    this.accounts.push(account);
  }

  setInventory(inventory){
    this.inventory = inventory;
  }

  setKeyword1(keyword1) {
    this.keyword1 = keyword1;
  }

  setKeyword2(keyword2) {
    this.keyword2 = keyword2;
  }

  setKeyword3(keyword3) {
    this.keyword3 = keyword3;
  }

  setAccountName(accountName) {
    this.accountName = accountName;
  }

  setStartDate(startDate) {
    this.startDate = startDate;
  }

  setStartHour(startHour) {
    this.startHour = startHour;
  }

  setStartMinute(startMinute) {
    this.startMinute = startMinute;
  }

  setFrequency = (frequency) => {
    this.frequency = frequency;
  }

  setCards(cards) {
    this.cards = cards;
  }

  setOpenAIKey(key) {
    this.openAIKey = key;
  }

  setAYRKey(ayrKey) {
    this.ayrKey = ayrKey;
  }

  setSelectedAccount(account) {
    this.selectedAccount = account;
  }

  setGeneratingContent(bool) {
    this.generatingContent = bool;
  }

  setInitialImage(image) {
    this.initialImage = image;
  }

  setInitialText(text) {
    this.initialText = text;
  }

  setInstagram(bool) {
    this.instagram = bool;
  }

  setTwitter(bool) {
    this.twitter = bool;
  }

  setFacebook(bool) {
    this.facebook = bool;
  }

  setLinkedIn(bool) {
    this.linkedIn = bool;
  }

  setBlog(bool) {
    this.blog = bool;
  }

  setHamburgerOpen(bool) {
    this.hamburgerOpen = bool;
  }

  setSelectedAccountData(data) {
    this.selectedAccountData = data;
  }

  setEmail(param) {
    this.email = param;
  }

  setPhone(param) {
    this.phone = param;
  }

  setWebsite(param) {
    this.website = param;
  }

  setWordCount(param) {
    this.wordCount = param;
  }

  setProfileImage(param) {
    this.profileImage = param;
  }

  setSignatures(param) {
    this.signatures = param;
  }

  setPayment(param) {
    this.payment = param;
  }
}
export const AccountStoreContext = createContext(new AccountStore());