import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import { AccountStoreContext } from '../store/AccountStore';
import FacebookPreview from '../FacebookPreview/FacebookPreview';
import InstagramPreview from '../InstagramPreview/InstagramPreview';
import LinkedInPreview from '../LinkedInPreview/LinkedInPreview';
import TwitterPreview from '../TwitterPreview/TwitterPreview';
import BlogPreview from '../BlogPreview/BlogPreview';
import FacebookPreviewMobile from '../FacebookPreview/FacebookPreviewMobile';
import InstagramPreviewMobile from '../InstagramPreview/InstagramPreviewMobile';
import TwitterPreviewMobile from '../TwitterPreview/TwitterPreviewMobile';
import LinkedInPreviewMobile from '../LinkedInPreview/LinkedInPreviewMobile';
import BlogPreviewMobile from '../BlogPreview/BlogPreviewMobile';

const SocialSection = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const accountStore = useContext(AccountStoreContext);
  const [isMobilePreviewEnabled, setIsMobilePreviewEnabled] = useState(true);
  const [isDesktopPreviewEnabled, setIsDesktopPreviewEnabled] = useState(false);

  const handleMobileClick = () => {
    setIsMobilePreviewEnabled(true);
    setIsDesktopPreviewEnabled(false);
  };

  const handleDesktopClick = () => {
    setIsMobilePreviewEnabled(false);
    setIsDesktopPreviewEnabled(true);
  };

  const onTitleClick = (index) => {
    setActiveIndex(index);
  };

  const isSocialMediaEnabled = (title) => {
    switch (title) {
      case 'Facebook':
        return accountStore.facebook;
      case 'Instagram':
        return accountStore.instagram;
      case 'LinkedIn':
        return accountStore.linkedIn;
      case 'Twitter':
        return accountStore.twitter;
      case 'Blog':
        return accountStore.blog;
      default:
        return false;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginBottom: 10 }}>
        <p style={{ fontSize: 33, fontWeight: 'bold', position:'relative', fontFamily: 'nexa_boldregular', margin: 8 }}>
          Social
        </p>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <div style={{ height: 17, width: 70, color: 'white', display: 'flex', justifyContent: 'center', opacity: isMobilePreviewEnabled ? '' : 0.5, alignItems: 'center', padding: 10, borderRadius: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontSize: 16.5 }}
            className="button" onClick={handleMobileClick}>Mobile
          </div>
          <div style={{ height: 17, width: 70, color: 'white', display: 'flex', justifyContent: 'center', opacity: isDesktopPreviewEnabled ? '' : 0.5, alignItems: 'center', padding: 10, borderRadius: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontSize: 16.5 }}
            className="button" onClick={handleDesktopClick}>Desktop
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
        <div style={{ display: "flex", flexDirection: "row", width: '100%' }}>
          {items.map((item, index) => {
            const isEnabled = isSocialMediaEnabled(item.title);

            return (
              <div key={index} style={{ width: '25%', paddingRight: 4 }}>
                <div style={{ height: 50, width: '100%', background: '#FFFFFF', border: '1px solid #ddd', borderTopLeftRadius: 20, borderTopRightRadius: 20, display: 'flex', position: 'relative', flexDirection: 'row', padding: 0, justifyContent: 'space-between', borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginBottom: 20, cursor: isDesktopPreviewEnabled ? 'pointer' : '' }}
                  onClick={() => onTitleClick(index)}>
                  <div style={{ display: 'flex', width: '25%', position: 'relative', alignItems: 'center', paddingLeft: 10, paddingRight: 10, fontFamily: 'nexa_boldregular' }}>
                    <img src={item.social} style={{ marginRight: 10 }} alt="" />
                    {item.title}
                  </div>
                  <div style={{ backgroundColor: '#4C6FFF', opacity: isEnabled ? '' : 0.5, width: 16, borderTopRightRadius: 20, borderBottomRightRadius: 20  }} />
                </div>
              </div>
            );
          })}
        </div>
        {isMobilePreviewEnabled ? 
        <div style={{ display: "flex", flexDirection: "row", maxWidth: '100%', marginBottom: 20, height: 400 }}>
          <div style={{ width: '25%', maxWidth: '25%', marginRight: 4, padding: 2, backgroundColor: 'white', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', borderRadius: 8, boxSizing: 'border-box', overflow: 'hidden' }}>
            <FacebookPreviewMobile />
          </div>
          <div style={{ width: '25%', maxWidth: '25%', marginRight: 4, padding: 2, backgroundColor: 'white', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', borderRadius: 8, boxSizing: 'border-box', overflow: 'hidden' }}>
            <InstagramPreviewMobile/>
          </div>
          <div style={{ width: '25%', maxWidth: '25%', marginRight: 4, padding: 2, backgroundColor: 'white', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', borderRadius: 8, boxSizing: 'border-box', overflow: 'hidden' }}>
            <TwitterPreviewMobile/>
          </div>
          <div style={{ width: '25%', maxWidth: '25%', marginRight: 4, padding: 2, backgroundColor: 'white', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', borderRadius: 8, boxSizing: 'border-box', overflow: 'hidden' }}>
            <LinkedInPreviewMobile/>
          </div>
          <div style={{ width: '25%', maxWidth: '25%', marginRight: 4, padding: 2, backgroundColor: 'white', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', borderRadius: 8, boxSizing: 'border-box', overflow: 'hidden' }}>
            <BlogPreviewMobile/>
          </div>
        </div> :
          <div style={{ display: "flex", flexDirection: "row", width: 580, marginBottom: 20, height: 400 }}>
            {activeIndex === 0 ? <FacebookPreview /> : "" }
            {activeIndex === 1 ? <InstagramPreview /> : "" }
            {activeIndex === 2 ? <TwitterPreview /> : "" }
            {activeIndex === 3 ? <LinkedInPreview /> : "" }
            {activeIndex === 4 ? <BlogPreview /> : "" }
          </div>
        }
      </div>
    </div>
  );
};
export default observer(SocialSection);