import { observer } from 'mobx-react';
import TabMenu from '../TabMenu/TabMenu';
import React, { useContext } from 'react';
import { IsMobileContext } from '../Tools/IsMobileContext';

const LeftSection = observer(() => {

  const isMobile = useContext(IsMobileContext);

  return (
    <div style={{ width: '100%', height: '100%', flexDirection: 'column', display: 'flex' }}>
      {!isMobile && <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', margin: 8 }}>Prep</p>}
      <div style={{ background: 'white', marginBottom: 16 }}>
        <TabMenu />
      </div>
    </div>
  );
})
export default LeftSection;